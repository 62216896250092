import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

type SEOProps = {
  title?: string
  description?: string
  yoastSeo?: any
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    wp {
      generalSettings {
        title
        description
      }
    }
  }
`

const SEO: React.FC<SEOProps> = ({ yoastSeo, ...props }) => {
  const data = useStaticQuery(query)
  const { pathname } = useLocation()

  const fields = {
    title:
      yoastSeo?.title || props.title || data.wp.generalSettings.title || ``,
    description:
      yoastSeo?.metaDesc ||
      props.description ||
      data.wp.generalSettings.description ||
      ``,
    canonical: yoastSeo?.canonical || data.site.siteMetadata.siteUrl + pathname,
  }

  const og = {
    title: yoastSeo?.opengraphTitle || fields.title,
    description: yoastSeo?.opengraphDescription || fields.description,
    type: yoastSeo?.opengraphType || "website",
    url: fields.canonical,
    siteName:
      yoastSeo?.opengraphSiteName ||
      data.wp.generalSettings.title ||
      data.site.siteMetadata.title,
    image: yoastSeo?.opengraphImage?.localFile?.publicURL
      ? `${data.site.siteMetadata.siteUrl}${yoastSeo?.opengraphImage?.localFile?.publicURL}`
      : null,
  }

  const twitter = {
    title: yoastSeo?.twitterTitle || fields.title,
    description: yoastSeo?.twitterDescription || fields.description,
    url: fields.canonical,
    siteName: og.siteName,
    image: og.image,
    // image: yoastSeo?.twitterImage?.localFile?.publicURL
    //   ? `${data.site.siteMetadata.siteUrl}${yoastSeo?.twitterImage?.localFile?.publicURL}`
    //   : null,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>{fields.title}</title>
      <meta name="description" content={fields.description} />
      <link rel="canonical" href={fields.canonical} />

      <meta property="og:site_name" content={og.siteName} />
      <meta property="og:type" content={og.type} />
      <meta property="og:title" content={og.title} />
      <meta property="og:description" content={og.description} />
      <meta property="og:url" content={og.url} />
      {og.image && <meta property="og:image" content={og.image} />}

      <meta name="twitter:title" content={twitter.title} />
      <meta name="twitter:description" content={twitter.description} />
      <meta name="twitter:url" content={twitter.url} />
      {twitter.image && <meta name="twitter:image" content={twitter.image} />}
      {twitter.image && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {/* <script type="application/ld+json"></script> */}
    </Helmet>
  )
}

export default SEO
